import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    name: 'GoBack',
    setup() {
        const router = useRouter();
        const goBack = () => {
            router.go(-1);
        };
        return {
            goBack,
        };
    },
});
