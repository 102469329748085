import { defineComponent } from 'vue';
import GoBack from './GoBack.vue';
export default defineComponent({
    components: { GoBack },
    name: 'ButtonLayer',
    props: {
        back: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
});
