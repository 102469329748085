import { defineComponent } from 'vue';
export default defineComponent({
    name: 'InputField',
    props: {
        modelValue: {
            type: String,
            required: false,
            default: '',
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        type: {
            type: String,
            required: false,
            default: 'text',
        },
    },
    emits: ['input-click', 'update:modelValue', 'change'],
    methods: {
        inputHandler(e) {
            const input = e.target;
            this.$emit('update:modelValue', input.value);
            this.$emit('change', input.value);
        },
        clickHandler(e) {
            this.$emit('input-click', e);
        },
    },
});
